import React, { useState, useCallback } from 'react';
import { 
  Button, 
  Space,
  message
} from 'antd';
import CampaignManager from './CampaignManager';
import ResultsManager from './ResultsManager';
import { callsAPI } from '../../services/api';

const ExperimentalPage = () => {
  const [activeTab, setActiveTab] = useState('campaign');
  const [contacts, setContacts] = useState([]);
  const [completedCalls, setCompletedCalls] = useState([]);
  const [csvUploaded, setCsvUploaded] = useState(false);

// Change this in updateCallStatus
const updateCallStatus = useCallback((callSid, newStatus, summaryData = null) => {
  setContacts(prevContacts => 
    prevContacts.map(contact => {
      // Check both callSid and key to ensure we match the contact
      if (contact.callSid === callSid || contact.key === callSid) {
        return {
          ...contact,
          callSid: callSid, // Ensure callSid is set
          status: newStatus,
          ...(summaryData ? { summary: summaryData.summary, structuredSummary: summaryData.structuredSummary } : {})
        };
      }
      return contact;
    })
  );
}, []);

  // Handle batch start
  const handleCallStart = (batchContacts) => {
    setContacts(prevContacts => 
      prevContacts.map(contact => ({
        ...contact,
        status: batchContacts.some(bc => bc.key === contact.key) 
          ? 'in-progress' 
          : contact.status
      }))
    );
  };

  // Handle individual call completion
  const handleCallComplete = async (callData) => {
    try {
      const { success, summary, conversation } = await callsAPI.getCallSummary(callData.callSid);

      if (success) {
        const completedCall = {
          ...callData,
          timestamp: summary.metadata.timestamp || new Date().toISOString(),
          duration: summary.metadata.duration || 0,
          conversation: conversation || '',
          summary: summary.raw || '',
          structuredSummary: summary.parsed || null,
          status: 'completed'
        };

        // Update both states atomically
        setCompletedCalls(prev => [...prev, completedCall]);
        
        // Pass the contact key if callSid isn't available
        const identifier = callData.callSid || callData.key;
        updateCallStatus(identifier, 'completed', {
          summary: summary.raw || '',
          structuredSummary: summary.parsed || null
        });

        message.success(`Call completed for ${callData.name}`);
      } else {
        message.error(`Failed to fetch summary for ${callData.name}`);
        updateCallStatus(callData.callSid || callData.key, 'error');
      }
    } catch (error) {
      console.error('Error fetching call summary:', error);
      message.error(`Failed to fetch summary for ${callData.name}`);
      updateCallStatus(callData.callSid || callData.key, 'error');
    }
};

  const handleBatchComplete = async (results) => {
    await Promise.all(results.map(result => handleCallComplete(result)));
};

  return (
    <Space direction="vertical" size="large" style={{ width: '100%', textAlign: 'center' }}>
      <div style={{ 
        display: 'inline-block', 
        marginBottom: '24px',
        background: 'white',
        padding: '0px',
        borderRadius: '8px',
      }}>
        <Space size={0}>
          <Button
            type="text"
            onClick={() => setActiveTab('campaign')}
            style={{
              height: '40px',
              padding: '0 24px',
              borderRadius: '6px 0px 0px 6px',
              fontWeight: 500,
              backgroundColor: activeTab === 'campaign' ? '#2c2c2c' : '#EDEDED',
              color: activeTab === 'campaign' ? 'white' : '#666666',
              border: 'none'
            }}
          >
            Start Campaign
          </Button>
          <Button
            type="text"
            onClick={() => setActiveTab('results')}
            style={{
              height: '40px',
              padding: '0 24px',
              borderRadius: '0px 6px 6px 0px',
              fontWeight: 500,
              backgroundColor: activeTab === 'results' ? '#2c2c2c' : '#EDEDED',
              color: activeTab === 'results' ? 'white' : '#666666',
              border: 'none',
              marginLeft: '0px'
            }}
          >
            View Results
          </Button>
        </Space>
      </div>
      
      {activeTab === 'campaign' ? (
        <CampaignManager 
          contacts={contacts}
          setContacts={setContacts}
          csvUploaded={csvUploaded}
          setCsvUploaded={setCsvUploaded}
          onCallStart={handleCallStart}
          onCallComplete={handleCallComplete}
          updateCallStatus={updateCallStatus}
        />
      ) : (
        <ResultsManager 
          results={completedCalls}
          activeContacts={contacts.filter(c => c.status === 'in-progress')}
          onExport={() => {
            // Export functionality will be added here
          }}
        />
      )}
    </Space>
  );
};

export default ExperimentalPage;