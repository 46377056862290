import React, { useState } from 'react';
import { 
  Card, 
  Table, 
  Typography, 
  Tag, 
  Space, 
  Button,
  Input,
  DatePicker,
  Modal,
  Descriptions,
  Alert,
  Tabs
} from 'antd';
import {
  CheckCircleOutlined,
  SearchOutlined,
  CalendarOutlined,
  PhoneOutlined,
  ClockCircleOutlined,
  MessageOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import Papa from 'papaparse';

const { Title, Text, Paragraph } = Typography;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const ResultsManager = ({ results = [], activeContacts = [], onExport }) => {
  const [searchText, setSearchText] = useState('');
  const [dateRange, setDateRange] = useState(null);
  const [selectedCall, setSelectedCall] = useState(null);

  const processTextForCsv = (text) => {
    if (!text) return '';
    // Remove any existing quotes first, then wrap in quotes and escape internal quotes
    return `"${text.replace(/"/g, '""')}"`;
  };

  const handleExport = () => {
    const exportData = results.map(call => {
      // Get structured data if available
      const structuredSummary = call.structuredSummary || {};
      
      return {
        'Date & Time': new Date(call.timestamp).toLocaleString(),
        'Contact Name': call.name || '',
        'Phone Number': call.phone || '',
        'Property Address': call.property || '',
        'Call Duration (minutes)': formatDuration(call.duration),
        'Status': isCallSuccessful(call) ? 'Successful' : 'Incomplete',
        'Main Objective': processTextForCsv(structuredSummary.mainObjective || call.summary || ''),
        'Outcome': processTextForCsv(structuredSummary.outcome || ''),
        'Key Points': processTextForCsv(
          structuredSummary.keyPoints ? 
            structuredSummary.keyPoints.join('\n- ') : 
            ''
        ),
        'Next Steps': processTextForCsv(
          structuredSummary.nextSteps ? 
            structuredSummary.nextSteps.join('\n- ') : 
            ''
        ),
        'Full Conversation': processTextForCsv(call.conversation || ''),
        'Original Prompt': processTextForCsv(call.prompt || '')
      };
    });

    // Configure Papa Parse with proper escaping and encoding
    const csv = Papa.unparse(exportData, {
      quotes: true, // Force quotes around all fields
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n", // Standard CSV newline
      skipEmptyLines: false,
      encoding: "utf-8"
    });

    // Add BOM for Excel UTF-8 compatibility
    const bomPrefix = '\uFEFF';
    const csvContent = bomPrefix + csv;

    // Create and trigger download
    const blob = new Blob([csvContent], { 
      type: 'text/csv;charset=utf-8;' 
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `call_campaign_results_${new Date().toISOString().slice(0,10)}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  const formatDuration = (seconds) => {
    if (!seconds) return '0:00';
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const isCallSuccessful = (record) => {
    if (record.structuredSummary) {
      const outcome = record.structuredSummary.outcome?.toLowerCase() || '';
      return outcome.includes('success') || outcome.includes('scheduled') || 
             outcome.includes('interested') || outcome.includes('agreed');
    }
    const summary = record.summary?.toLowerCase() || '';
    return summary.includes('success') || summary.includes('scheduled') || 
           summary.includes('interested') || summary.includes('agreed');
  };

  const columns = [
    {
      title: 'Contact',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Space direction="vertical" size="small">
          <Text strong>{text}</Text>
          <Text type="secondary" style={{ fontSize: '12px' }}>
            <PhoneOutlined style={{ marginRight: 8 }} />
            {record.phone}
          </Text>
        </Space>
      ),
      filteredValue: searchText ? [searchText] : null,
      onFilter: (value, record) => 
        record.name.toLowerCase().includes(value.toLowerCase()) ||
        record.phone.includes(value)
    },
    {
      title: 'Property',
      dataIndex: 'property',
      key: 'property',
      ellipsis: true
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => {
        const success = isCallSuccessful(record);
        return (
          <Tag icon={success ? <CheckCircleOutlined /> : null} color={success ? 'success' : 'warning'}>
            {success ? 'Successful' : 'Incomplete'}
          </Tag>
        );
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button 
          type="link" 
          icon={<MessageOutlined />}
          onClick={() => setSelectedCall(record)}
        >
          View Details
        </Button>
      ),
    },
    {
      title: 'Duration',
      key: 'duration',
      render: (_, record) => (
          <Space>
              <ClockCircleOutlined />
              <Text>{formatDuration(record.duration || 0)}</Text>
          </Space>
      )
  },
  ];

  const getResultMessage = (summaryData) => {
    if (summaryData?.keyPoints) {
      const resultPoint = summaryData.keyPoints.find(point => 
        point.toLowerCase().includes('available') || 
        point.toLowerCase().includes('found') ||
        point.toLowerCase().includes('result')
      );
      if (resultPoint) return resultPoint;
    }
    return summaryData?.outcome || '';
  };

  const renderCallDetails = () => {
    if (!selectedCall) return null;

    const summaryData = selectedCall.structuredSummary || {
      mainObjective: selectedCall.summary,
      outcome: '',
      keyPoints: [],
      nextSteps: []
    };

    const isSuccessful = isCallSuccessful(selectedCall);

    return (
      <div className="call-details-content">
        <Descriptions bordered>
          <Descriptions.Item label="Date" span={2}>
            {formatDate(selectedCall.timestamp)}
          </Descriptions.Item>
          <Descriptions.Item label="Duration">
            {formatDuration(selectedCall.duration)}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number" span={2}>
            {selectedCall.phone}
          </Descriptions.Item>
          <Descriptions.Item label="Property">
            {selectedCall.property}
          </Descriptions.Item>
        </Descriptions>

        <Tabs defaultActiveKey="summary" className="details-tabs">
          <TabPane tab="Summary" key="summary">
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              <Alert
                message={isSuccessful ? "Task Completed Successfully" : "Task Incomplete"}
                type={isSuccessful ? "success" : "warning"}
                showIcon
                style={{ alignItems: 'center', display: 'flex' }}
              />

              {getResultMessage(summaryData) && (
                <Alert
                  message="Result"
                  description={<Text strong>{getResultMessage(summaryData)}</Text>}
                  type="info"
                  showIcon
                />
              )}

              <Card title="Objective" className="detail-card">
                <Paragraph>
                  {summaryData?.mainObjective || selectedCall.summary || 'No objective available'}
                </Paragraph>
              </Card>

              {summaryData?.keyPoints?.length > 0 && (
                <Card title="Key Points" className="detail-card">
                  <ul className="points-list">
                    {summaryData.keyPoints.map((point, index) => (
                      <li key={index}>{point}</li>
                    ))}
                  </ul>
                </Card>
              )}

              {summaryData?.nextSteps?.length > 0 && (
                <Card title="Next Steps" className="detail-card">
                  <ul className="points-list">
                    {summaryData.nextSteps.map((step, index) => (
                      <li key={index}>{step}</li>
                    ))}
                  </ul>
                </Card>
              )}
            </Space>
          </TabPane>

          <TabPane tab="Conversation" key="conversation">
            <Card className="detail-card">
              <div className="prompt-section">
                <Text type="secondary">Original Prompt</Text>
                <Paragraph strong style={{ marginTop: 8 }}>
                  {selectedCall.prompt || 'No prompt available'}
                </Paragraph>
              </div>
              
              <div className="conversation-section">
                {selectedCall.conversation ? (
                  <pre className="conversation-text">
                    {selectedCall.conversation}
                  </pre>
                ) : (
                  <Text type="secondary">No conversation transcript available</Text>
                )}
              </div>
            </Card>
          </TabPane>
        </Tabs>
      </div>
    );
  };

  const filteredResults = results.filter(record => {
    let matchesSearch = true;
    let matchesDate = true;

    if (searchText) {
      matchesSearch = 
        record.name.toLowerCase().includes(searchText.toLowerCase()) ||
        record.phone.includes(searchText) ||
        record.property.toLowerCase().includes(searchText.toLowerCase());
    }

    if (dateRange) {
      const recordDate = new Date(record.timestamp);
      matchesDate = 
        recordDate >= dateRange[0] &&
        recordDate <= dateRange[1];
    }

    return matchesSearch && matchesDate;
  });

  return (
    <Card
      bordered={false}
      style={{
        borderRadius: '12px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
      }}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Space 
          style={{ 
            justifyContent: 'space-between', 
            width: '100%',
            background: '#fafafa',
            padding: '16px',
            borderRadius: '8px'
          }}
        >
          <Title level={5} style={{ margin: 0 }}>Campaign Results</Title>
          <Space>
            <Button 
              icon={<DownloadOutlined />} 
              onClick={handleExport}
              disabled={results.length === 0}
            >
              Export Results
            </Button>
            <RangePicker 
              onChange={setDateRange}
              placeholder={['Start Date', 'End Date']}
            />
            <Input
              placeholder="Search contacts..."
              prefix={<SearchOutlined />}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              style={{ width: 200 }}
            />
          </Space>
        </Space>

        <Table
          columns={columns}
          dataSource={filteredResults}
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} calls`
          }}
          style={{ 
            background: 'white',
            borderRadius: '8px'
          }}
        />

        <Modal
          title="Call Details"
          open={!!selectedCall}
          onCancel={() => setSelectedCall(null)}
          width={800}
          footer={null}
          className="call-details-modal"
        >
          {renderCallDetails()}
        </Modal>
      </Space>
    </Card>
  );
};

export default ResultsManager;