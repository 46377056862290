import React, { useState, useEffect, useRef } from 'react';
import { 
  Card, 
  Typography, 
  Upload, 
  Button, 
  Table, 
  Input,
  Space,
  Tag,
  Tooltip,
  message
} from 'antd';
import {
  UploadOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import Papa from 'papaparse';
import { callsAPI } from '../../services/api';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { Dragger } = Upload;

const BASE_WS_URL = process.env.REACT_APP_API_URL?.replace('http', 'ws') || 'wss://aigent-backend.onrender.com';

const CampaignManager = ({ 
  contacts,
  setContacts,
  csvUploaded,
  setCsvUploaded,
  onCallStart,
  onCallComplete 
}) => {
  const [basePrompt, setBasePrompt] = useState('');
  const [processing, setProcessing] = useState(false);
  const [activeBatch, setActiveBatch] = useState([]);
  const wsConnections = useRef(new Map());
  const activeCallData = useRef(new Map());
  const pollTimeoutRef = useRef(new Map());
  

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Property Address',
      dataIndex: 'property',
      key: 'property',
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => {
        let color = 'default';
        let icon = <ClockCircleOutlined />;
        let text = 'Pending';

        if (record.status === 'completed') {
          color = 'success';
          icon = <CheckCircleOutlined />;
          text = 'Completed';
        } else if (record.status === 'in-progress') {
          color = 'processing';
          icon = <SyncOutlined spin />;
          text = 'In Progress';
        }

        return (
          <Tag icon={icon} color={color}>
            {text}
          </Tag>
        );
      }
    }
  ];

  useEffect(() => {
    return () => {
      wsConnections.current.forEach(ws => ws.close());
      wsConnections.current.clear();
      activeCallData.current.clear();
      pollTimeoutRef.current.forEach(timeout => clearTimeout(timeout));
      pollTimeoutRef.current.clear();
    };
  }, []);

  const startPolling = async (callSid, contact) => {
    const maxAttempts = 10;
    const interval = 3000;
    let attempts = 0;

    const pollOnce = async () => {
        if (attempts >= maxAttempts) {
            console.log(`Max polling attempts reached for call ${callSid}`);
            return;
        }

        try {
            console.log(`Polling for summary, attempt ${attempts + 1} for call ${callSid}`);
            const { success, summary, conversation } = await callsAPI.getCallSummary(callSid);

            if (success) {
                const completedCall = {
                    ...contact,
                    callSid,
                    timestamp: summary.metadata.timestamp || new Date().toISOString(),
                    duration: summary.metadata?.duration || 
                             (summary.creditInfo?.minutesUsed ? summary.creditInfo.minutesUsed * 60 : 0),
                    conversation: conversation || '',
                    summary: summary.raw || '',
                    structuredSummary: summary.parsed || null,
                    status: 'completed'
                };

                onCallComplete?.(completedCall);
                pollTimeoutRef.current.delete(callSid);
                return;
            }

            attempts++;
            const timeout = setTimeout(pollOnce, interval);
            pollTimeoutRef.current.set(callSid, timeout);
        } catch (error) {
            console.error(`Error polling summary for call ${callSid}:`, error);
            attempts++;
            const timeout = setTimeout(pollOnce, interval);
            pollTimeoutRef.current.set(callSid, timeout);
        }
    };

    pollOnce();
};

const setupCallMonitoring = (callSid, contact) => {
  const token = localStorage.getItem('token');
  if (!token || !callSid) return;

  const wsUrl = `${BASE_WS_URL}/ws/call-monitor?token=${encodeURIComponent(token)}&callSid=${encodeURIComponent(callSid)}`;
  const ws = new WebSocket(wsUrl);
  
  ws.onopen = () => {
      console.log(`WebSocket connected for call ${callSid}`);
      updateCallStatus(callSid, { status: 'in-progress' }, contact);
  };

  ws.onmessage = async (event) => {
    try {
        const message = JSON.parse(event.data);
        
        if (message.type === 'call_status' && message.data.status === 'completed') {
            // First update local state immediately
            setContacts(prevContacts => 
                prevContacts.map(c => 
                    c.key === contact.key
                        ? { ...c, status: 'completed' }
                        : c
                )
            );
            
            // Then get summary and notify parent (this updates View Results)
            const { success, summary } = await callsAPI.getCallSummary(callSid);
            if (success) {
                const completedCall = {
                    ...contact,
                    callSid,
                    timestamp: summary.metadata?.timestamp || new Date().toISOString(),
                    duration: summary.metadata?.duration || 0,
                    conversation: summary.conversation || '',
                    summary: summary.raw || '',
                    structuredSummary: summary.parsed || null,
                    status: 'completed'
                };
                
                onCallComplete?.(completedCall);
            }
            
            ws.close();
        }
    } catch (err) {
        console.error('Error parsing WebSocket message:', err);
    }
};

  ws.onerror = (error) => {
      console.error(`WebSocket error for call ${callSid}:`, error);
      updateCallStatus(callSid, { status: 'error' }, contact);
  };

  ws.onclose = () => {
      console.log(`WebSocket closed for call ${callSid}, starting polling`);
      wsConnections.current.delete(callSid);
      startPolling(callSid, contact);
  };

  wsConnections.current.set(callSid, ws);
};
  const handleCallStatusUpdate = (callSid, message, contact) => {
    if (!message.type) return;

    switch (message.type) {
      case 'call_status':
        updateCallStatus(callSid, message.data, contact);
        break;
      case 'error':
        handleCallError(callSid, message.data, contact);
        break;
      default:
        break;
    }
  };

  const updateCallStatus = (callSid, statusData, contact) => {
    const currentData = activeCallData.current.get(callSid) || {};
    const newData = { 
        ...currentData, 
        status: statusData.status,
        duration: statusData.duration || currentData.duration || 0 
    };
    activeCallData.current.set(callSid, newData);

    setContacts(prevContacts => 
        prevContacts.map(c => 
            c.key === contact.key
                ? { 
                    ...c, 
                    status: statusData.status === 'completed' ? 'completed' : 'in-progress',
                    callData: newData,
                    duration: newData.duration
                }
                : c
        )
    );

    if (statusData.status === 'completed') {
        cleanupCall(callSid);
    }
};

  const handleCallError = (callSid, errorData, contact) => {
    setContacts(prevContacts =>
      prevContacts.map(c =>
        c.key === contact.key
          ? { ...c, status: 'failed', error: errorData.message }
          : c
      )
    );
    cleanupCall(callSid);
  };

  const cleanupCall = (callSid) => {
    const ws = wsConnections.current.get(callSid);
    if (ws) {
      ws.close();
      wsConnections.current.delete(callSid);
    }
    activeCallData.current.delete(callSid);
  };

  const processCSV = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          try {
            const processedContacts = [];
            
            results.data.forEach((row, index) => {
              const phoneNumbers = row['Landline Numbers']?.split('\n')
                .map(phone => phone.replace(/\s*-\s*Landline/, '').trim())
                .filter(phone => phone && phone !== 'Landline');
  
              if (phoneNumbers && phoneNumbers.length > 0) {
                phoneNumbers.forEach((phone) => {
                  processedContacts.push({
                    key: `${index}-${phone}`,
                    name: row['Contact Person']?.replace(/\n/g, ' ').trim(),
                    phone: phone,
                    property: row['Address'],
                    status: 'pending'
                  });
                });
              }
            });
  
            console.log('Processed contacts:', processedContacts);
            resolve(processedContacts);
          } catch (error) {
            console.error('CSV Processing error:', error);
            reject(new Error('Error processing CSV: ' + error.message));
          }
        },
        error: (error) => {
          console.error('CSV Parsing error:', error);
          reject(new Error('Error parsing CSV: ' + error.message));
        }
      });
    });
  };

  const handleCsvUpload = async (info) => {
    const { file } = info;
    
    if (file.status !== 'uploading') {
      try {
        setProcessing(true);
        const processedContacts = await processCSV(file.originFileObj);
        setContacts(processedContacts);
        setCsvUploaded(true);
        message.success(`Successfully processed ${processedContacts.length} contacts`);
      } catch (error) {
        message.error(error.message);
      } finally {
        setProcessing(false);
      }
    }
  };

  const customizePrompt = (basePrompt, contact) => {
    return basePrompt
      .replace('{name}', contact.name)
      .replace('{property}', contact.property)
      .replace('{address}', contact.property);
  };

  const startBatchCalls = async () => {
    if (!basePrompt.trim()) {
      message.error('Please enter a base prompt');
      return;
    }

    const pendingContacts = contacts.filter(c => c.status === 'pending');
    if (pendingContacts.length === 0) {
      message.info('No pending contacts to call');
      return;
    }

    const batchSize = 1;
    const currentBatch = pendingContacts.slice(0, batchSize);
    setActiveBatch(currentBatch);
    onCallStart?.(currentBatch);

    try {
      setProcessing(true);
      
      const callPromises = currentBatch.map(async (contact) => {
        const customPrompt = customizePrompt(basePrompt, contact);
        
        try {
          setContacts(prev => 
            prev.map(c => 
              c.key === contact.key ? { ...c, status: 'in-progress' } : c
            )
          );

          const result = await callsAPI.makeCall(contact.phone, customPrompt);
          
          if (result.callSid) {
            setContacts(prev =>
              prev.map(c =>
                c.key === contact.key 
                  ? { ...c, status: 'in-progress', callSid: result.callSid } 
                  : c
              )
            );
            setupCallMonitoring(result.callSid, contact);
          } else {
            throw new Error('No call ID received');
          }
          
        } catch (error) {
          console.error(`Call failed for ${contact.name}:`, error);
          return {
            ...contact,
            status: 'failed',
            error: error.message
          };
        }
      });

      await Promise.all(callPromises);
      message.success(`Started batch of ${currentBatch.length} calls`);
    } catch (error) {
      message.error('Error processing batch: ' + error.message);
    } finally {
      setProcessing(false);
      setActiveBatch([]);
    }
  };

  const resetUpload = () => {
    setCsvUploaded(false);
    setContacts([]);
    setActiveBatch([]);
  };

  return (
    <Card
      bordered={false}
      style={{
        borderRadius: '12px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
      }}
    >
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        <div>
          <Title level={5} style={{ marginBottom: '16px' }}>Campaign Prompt</Title>
          <Paragraph type="secondary" style={{ marginBottom: '16px' }}>
            Enter your base prompt below. This will be automatically customized for each contact with their specific details.
            <Tooltip title="Use {name} for contact name and {property} for property address">
              <InfoCircleOutlined style={{ marginLeft: 8 }} />
            </Tooltip>
          </Paragraph>
          <TextArea
            rows={6}
            value={basePrompt}
            onChange={(e) => setBasePrompt(e.target.value)}
            placeholder="Enter your base prompt here. Use {name} and {property} as placeholders..."
            style={{ borderRadius: '8px' }}
          />
        </div>

        {!csvUploaded ? (
          <div>
            <Title level={5} style={{ marginBottom: '16px' }}>Upload Contact List</Title>
            <Dragger
              name="file"
              multiple={false}
              beforeUpload={() => false}
              accept=".csv"
              onChange={handleCsvUpload}
              style={{
                background: '#fafafa',
                borderRadius: '12px',
                padding: '40px 20px',
                border: '2px dashed #e6e6e6'
              }}
            >
              <p className="ant-upload-drag-icon" style={{ marginBottom: '16px' }}>
                <UploadOutlined style={{ fontSize: '32px', color: '#1890ff' }} />
              </p>
              <p className="ant-upload-text" style={{ fontSize: '16px', marginBottom: '8px' }}>
                Click or drag CSV file to this area to upload
              </p>
              <p className="ant-upload-hint" style={{ color: '#8c8c8c' }}>
                CSV should contain: Name, Phone Number, Property Address
              </p>
            </Dragger>
          </div>
        ) : (
          <div>
            <Space style={{ 
              marginBottom: 24, 
              justifyContent: 'space-between', 
              width: '100%',
              background: '#fafafa',
              padding: '16px',
              borderRadius: '8px'
            }}>
              <Title level={5} style={{ margin: 0 }}>Contact List</Title>
              <Space>
                <Button 
                  icon={<DeleteOutlined />} 
                  onClick={resetUpload}
                  disabled={activeBatch.length > 0}
                >
                  Cancel and Upload New CSV
                </Button>
                <Button 
                  type="primary"
                  onClick={startBatchCalls}
                  disabled={!basePrompt.trim() || activeBatch.length > 0}
                  loading={processing}
                  style={{ borderRadius: '6px' }}
                >
                  Start Next Call
                </Button>
              </Space>
            </Space>
            <Table 
              columns={columns} 
              dataSource={contacts}
              rowKey="key"
              pagination={false}
              style={{ 
                background: 'white',
                borderRadius: '8px'
              }}
            />
          </div>
        )}
      </Space>
    </Card>
  );
};

export default CampaignManager;